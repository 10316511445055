import React from "react";

const Home = () => {
  return (
    <>
      <div className="home">
        <p style={{ fontSize: "40px" }}>Keep all your memories here and show others</p>
      </div>
    </>
  );
};

export default Home;
