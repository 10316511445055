const NotFound = () => {
  return (
    <>
      <div className="not-found">
        <p style={{ fontSize: "40px" }}>404 | Page not found!</p>
      </div>
    </>
  );
};

export default NotFound;
